import HodlsSwap from './Pages/HodlsSwap';

function App() {
  return (
   <div className=''>
   <HodlsSwap/>
   </div>
  );
}

export default App;
