import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Main() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <section
      data-aos="zoom-in-up"
      className="max-w-screen-xl mx-4  mt-8 xl:mt-1 2xl:mt-10 gap-28 text-white xl:pt-1 2xl:pt-16 flex flex-col items-center md:mx-auto  "
    >
      <main className=" md:w-[70%]  gap-4  flex flex-col items-center justify-center ">
        <div className=" text-center ">
          <h1 className="font-bebas font-normal text-3xl lg:text-7xl md:text-5xl flex-wrap  z-10 relative ">
            Uncover Hidden Treasures with HodlSwap!
          </h1>
        </div>
        <div className="flex flex-col gap-4 items-center  md:w-[90%] md:h-60 z-10">
          <div className="font-nunito text-center    md:text-lg  text-gray-100  ">
            <p className="font-nunito">
              HodlSwap is your ultimate destination for a thrilling token
              treasure hunt! Dive into our platform where users can earn tokens
              through various mining app features. The best part? Most of the
              tokens go directly to the players! Assemble your squad and
              maximize your rewards – more buddies mean more coins.
            </p>
          </div>
          <a
            href="https://t.me/HodlSwap_bot?start"
            className="bg-white text-[#870af5] font-bold  p-4 px-14 rounded-lg  transform transition-transform duration-300 hover:scale-105 "
          >
            JOIN HODLSWAP
          </a>
        </div>
      </main>
    </section>
  );
}

export default Main;
