import React from "react";
import bitlogo from "../assets/bitlogo.png";
import coin from "../assets/coin.png";
import play from "../assets/play.png";
import money from "../assets/money.png";

const Cards = () => {
  const cardsData = [
    {
      image: coin,
      title: "The Ultimate Token Adventure Discover HodlSwap!",
      description:
        "HodlSwap offers a token treasure hunt! Earn tokens through games, tasks, and referrals. Most rewards go to players. Dive in now!",
    },
    {
      image: money,
      title: "Secure and Convert Your Coins HodlSwap Wallet",
      description:
        "HodlSwap’s web3 wallet securely stores earned coins, ready for conversion. Manage and exchange tokens easily within the app.",
    },
    {
      image: play,
      title: "Engage in Fun Features Cool Features to Explore",
      description:
        "Engage in Mining Coins, complete tasks, and refer friends to earn more tokens. Enjoy boosting rewards!",
    },
  ];

  return (
    <div className=" bg-[#3C0465] py-4 md:py-8 rounded-3xl">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-4 z-10">
          {cardsData.map((card, index) => (
            <div
              key={index}
              className="bg-[#9814FB] flex flex-col rounded-3xl shadow-lg p-4 cursor-pointer transform transition-transform duration-300 hover:scale-105"
              onClick={(e) => {}}
            >
              <div className="flex lg:before:space-x-4 px-6  xl:flex-row flex-col">
                <img
                  src={card.image}
                  alt={card.title}
                  className="bg-[#3C0465] w-12 h-12 md:h-16 md:w-16 lg:w-20 lg:h-20   rounded-2xl object-cover transform transition-transform duration-300 hover:scale-110"
                />
                <div className=" text-lg md:text-xl font-bold flex items-center">
                  <p className="text-white lg xl:text-2xl font-bold ">{card.title}</p>
                </div>
              </div>

              <div className="px-6 py-2">
                <p className="text-white sm:text-xl">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cards;
