// MyIcon.jsx
import React from 'react';

const MyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 1365.55 449.85"
      className="w-40 h-16"
    >
      <defs>
        <style>
          {`
            .cls-1 {
              fill: url(#radial-gradient);
            }

            .cls-1, .cls-2, .cls-3 {
              stroke-width: 0px;
            }

            .cls-2 {
              fill: #fff;
            }

            .cls-3 {
              fill: #300c4f;
            }
          `}
        </style>
        <radialGradient
          id="radial-gradient"
          cx="243.14"
          cy="243.77"
          fx="227.85"
          fy="231.85"
          r="252.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stopColor="#d39530" />
          <stop offset=".34" stopColor="#ddae80" />
          <stop offset=".62" stopColor="#c1810b" />
          <stop offset=".79" stopColor="#e9bb87" />
        </radialGradient>
      </defs>
      {/* Removed the background rect */}
      <path
        className="cls-1"
        d="M398.03,225.27c0,84.97-68.88,153.86-153.85,153.86-24.08,0-46.87-5.53-67.16-15.39v-216.53h25.45v67.94h89.86v-78.05h-20.02v58.04h-49.82v-47.93h.47v-20.02h-66.16v224.74c-40.16-27.77-66.48-74.14-66.48-126.66,0-84.97,68.89-153.86,153.86-153.86,26.68,0,51.78,6.79,73.65,18.74v213.06h-25.45v-67.94h-89.86v78.05h20.02v-58.04h49.82v47.93h-.47v20.02h66.16V103.36c36.48,28.13,59.98,72.28,59.98,121.91Z"
      />
      <g>
        <path
          className="cls-2"
          d="M449.32,310.55v-158.97h34.97v60.64h82.67v-60.64h34.97v158.97h-34.97v-66.09h-82.67v66.09h-34.97Z"
        />
        <path
          className="cls-2"
          d="M662.8,291.47c-15.75-14.99-23.62-35.12-23.62-60.41s7.87-45.42,23.62-60.41c15.59-15.14,36.87-22.71,63.82-22.71s48.22,7.57,63.82,22.71c15.75,14.99,23.62,35.13,23.62,60.41s-7.87,45.42-23.62,60.41c-15.6,15.14-36.87,22.71-63.82,22.71s-48.22-7.57-63.82-22.71ZM764.54,268.76c9.08-9.08,13.63-21.65,13.63-37.7s-4.47-28.46-13.4-37.7c-9.08-9.23-21.73-13.85-37.93-13.85s-28.92,4.62-38.15,13.85-13.63,21.65-13.63,37.7,4.46,28.47,13.4,37.7c9.08,9.24,21.72,13.85,37.93,13.85s28.92-4.62,38.15-13.85Z"
        />
        <path
          className="cls-2"
          d="M851.29,310.55v-158.97h77.9c24.83,0,44.36,7.04,58.59,21.12,14.23,14.08,21.35,33.46,21.35,58.14s-7.12,44.14-21.35,58.37c-14.23,14.23-33.76,21.35-58.59,21.35h-77.9ZM886.27,278.98h42.7c29.52,0,44.29-15.9,44.29-47.69s-14.76-48.15-44.29-48.15h-42.7v95.84Z"
        />
        <path
          className="cls-2"
          d="M1046.37,310.55v-158.97h34.97v126.72h90.61l-1.14,32.25h-124.45Z"
        />
      </g>
    </svg>
  );
};

export default MyIcon;
