import Footer from "../Compoents/Footer";
import Main from "../Compoents/Main";
import Navbar from "../Compoents/Navbar";
import Cards from "../Compoents/Cards";
import backImage from "../assets/backImage.png";
import backgroundImage from "../assets/backgroundImage.jpg";
import landscape from "../assets/landscape.png";

function HodlsSwap() {
  // linear-gradient(to left,rgba(137,3,248,255), rgba(106, 93, 158, 0.8)),
  // url('https://res.cloudinary.com/dcn6fqcdd/image/upload/v1717160411/cr6lq7w6ulim4tjij7ab.png')

  const bgImage1 = {
    backgroundImage: `
      linear-gradient(to left,rgba(137,3,248,255), rgba(106, 93, 158, 0.8)),

  `,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <section className="md:h-[100vh] flex flex-col bg-custom-radial overflow-x-hidden">
      <div className="opacity-40 animate-float flex justify-center">
        <img className="sm:w-1/2 2xl:w-2/5 absolute top-40" src={landscape} />
      </div>
      <div className="">
        <Navbar />
        <Main />
        <div className="my-4 sm:my-8 mx-4 md:mx-16 2xl:my-1">
          <Cards/>
        </div>
      </div>
      {/* <Footer /> */}
    </section>
  );
}

export default HodlsSwap;
